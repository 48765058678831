'use strict'

angular
    .module('cb.core.session-service')
    .factory('SessionService', ["$location", "$http", "$rootScope", "$window", "SwService", function ($location, $http, $rootScope, $window, SwService) {

        var sessionService = {
            Positions: [],
            Roles: null,
            Username: null,

            getTenant: function () {
                var protocol = $location.protocol();
                var tenant = protocol + '://';
                tenant += $location.host();
                var port = $location.port();
                if (!((protocol == 'http' && port == 80) || (protocol == 'https' && port == 443)))
                    tenant += ':' + port;

                return tenant;
            },

            getPositions: function () {
                return this.Positions;
            },

            setCredentials: function () {
                var data = this.getTokenPayload();
                this.Positions = data['memorableInfoPositions'] || [];
                this.Roles = data['roles'];
                this.Username = data['username'];
                $rootScope.Roles = this.Roles;

                switch (true) {
                    case this.Roles.indexOf('Sales Person') >= 0:
                    case this.Roles.indexOf('Fitter') >= 0:
                    case this.Roles.indexOf('Sales Person/Fitter') >= 0:
                    case this.Roles.indexOf('Sales Manager') >= 0:
                    case this.Roles.indexOf('Field Manager') >= 0:
                        $location.path('/ipad-dashboard');
						break;
					case this.Roles.filter(r => r.Name == 'Contracts').length > 0:
					case this.Roles.filter(r => r.Name == 'Operations Manager').length > 0:
						$location.path('/contract-console');
						break;
                    default:
                        $location.path('/');
                        break;
                };
            },

            // Clear all credentials saved in the session-service
            clearUserCredentials: function () {
                this.Positions = [];
                this.Roles = null;
                this.Username = null;
                delete $rootScope.Roles;
                delete $rootScope.loggedIn;
                delete $rootScope.User;
            },

			logout: function () {
				
                delete $window.localStorage.cbToken;
				this.clearUserCredentials();

				if (document.location.hostname == "localhost")
					window.location.href = 'https://localhost:7101/Identity/Account/Login';

				if (document.location.hostname == "uat.specialistblinds.co.uk")
					window.location.href = 'https://uat.specialistblinds.co.uk/portal/Identity/Account/Login';

				if (document.location.hostname == "hal.conservatoryblinds.co.uk")
					window.location.href = 'https://hal.conservatoryblinds.co.uk/portal/Identity/Account/Login';

            },

            isLoggedIn: function () {
                return sessionService.checkToken();
            },

            checkVerification: function () {
                if (this.checkToken())
                    return this.getTokenPayload()['verified'];
                return null;
            },

            checkImpersonating: function () {
                if (this.checkToken()) {
                    if (this.getTokenPayload()['ImId'] != "00000000-0000-0000-0000-000000000000")
                        return true;
                }
                return false;
            },

            checkExpiredPassword: function () {
                if (this.checkToken()) {
                    return this.getTokenPayload()['requiresPasswordChange'];
                }
                return false;
            },

            checkToken: function () {
                var result = false;

                // Validate token
                if ($window.localStorage.cbToken) {
                    try {
                        var tokenArray = $window.localStorage.cbToken.split('.');
                        if (tokenArray.length == 3) {
                            result = true;
                        }
                    }
                    catch (e) {
                        console.debug(e);
                    }
                }
                return result;
            },

            getToken: function () {
                var result = null;
                if (sessionService.checkToken()) {
                    return $window.localStorage.cbToken;
                }
                return result;
            },

            getTokenPayload: function () {
                var result = null;
                var token = sessionService.getToken();
                if (token != null) {
                    var tokenArray = token.split('.');
                    var words = CryptoJS.enc.Base64.parse(tokenArray[1]);
                    return angular.fromJson(CryptoJS.enc.Utf8.stringify(words));
                }
                return result;
            },

            getAuthorisationHeader: function () {
                var result = null;
                var token = sessionService.getToken();
                if (token != null) {
                    var payload = sessionService.getTokenPayload();
                    // Check if expired
                    var timestamp = new Date().getTime();
                    if (payload.exp < timestamp) {
                        // Attempt to refresh
                        console.debug("REFRESH REQUIRED!");

                        $http
                            .post('/webapi/api/v2/account/refresh', { Token: token })
                            .success(function (data, status, headers, config) {
                                $window.localStorage.cbToken = data.Token;
                                console.debug("OLD TOKEN");
                                console.debug(token);
                                token = sessionService.getToken();
                                console.debug("NEW TOKEN");
                                console.debug(token);
                            })
                            .error(function (data, status, headers, config) {
                                // Erase the token if the user fails to log in
                                delete $window.localStorage.cbToken;

                                // Handle login errors here
                                $location.path('/login');
                            });
                    }

                    result = "Bearer " + token;
                }
                return result;
            },


            //################### HMAC Methods ###########################
            wordToByteArray: function (wordArray) {
                var byteArray = [], word, i, j;
                for (i = 0; i < wordArray.length; ++i) {
                    word = wordArray[i];
                    for (j = 3; j >= 0; --j) {
                        byteArray.push((word >> 8 * j) & 0xFF);
                    }
                }
                return byteArray;
            },

            //################### END OF HMAC METHODS ###########################
			test: function () {
				var url = '/webapi/api/v1/account/test';
				return $http.get(url);
			},

            // Service methods for returning the promises that we need for the login process
            attemptLogin: function (username, pwd) {
                var data = { Username: username, Password: pwd, Tenant: this.getTenant() };

                var url = '/webapi/api/v1/account/login';
                return $http.post(url, JSON.stringify(data));
			},

			attemptSingleSignIn: function (target) {
				var data = { Target: target, Tenant: this.getTenant() };

				var url = '/webapi/api/v1/account/SingleSignIn';
				return $http.post(url, JSON.stringify(data));
			},

			attemptDebugSingleSignIn: function (target) {
				var data = { Target: target, Tenant: this.getTenant() };

				var url = '/webapi/api/v1/account/DebugSingleSignIn';
				return $http.post(url, JSON.stringify(data));
			},

            attemptMemorableWord: function (data) {
                var url = '/webapi/api/v1/account/login/enterMemorableInformation';
                return $http.post(url, JSON.stringify(data));
            },

            forgottenPassword: function (data) {
                var url = '/webapi/api/v1/account/requestForgottenPasswordCode';
                return $http.post(url, JSON.stringify(data));
            },

            changeExpiredPassword: function (data) {
                var url = '/webapi/api/v1/account/login/ChangeExpiredPassword';
                return $http.post(url, JSON.stringify(data));
            }
        };

        return sessionService;
    }]);
